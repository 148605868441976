import { default as addzeyhGGmvELMeta } from "/usr/src/nuxt3-app/pages/attachment/add.vue?macro=true";
import { default as _91id_93oMy51eepxuMeta } from "/usr/src/nuxt3-app/pages/attachments/[id].vue?macro=true";
import { default as dashboardzSNYueQDdCMeta } from "/usr/src/nuxt3-app/pages/dashboard.vue?macro=true";
import { default as indexEAfN1CIUWoMeta } from "/usr/src/nuxt3-app/pages/index.vue?macro=true";
import { default as loginUVQbZYKsyxMeta } from "/usr/src/nuxt3-app/pages/login.vue?macro=true";
import { default as matchesATFdXCrSX8Meta } from "/usr/src/nuxt3-app/pages/matches.vue?macro=true";
import { default as notesrlkSR9s2HEMeta } from "/usr/src/nuxt3-app/pages/notes.vue?macro=true";
import { default as predictlzdytUN7MxMeta } from "/usr/src/nuxt3-app/pages/predict.vue?macro=true";
import { default as scoutFroJopb6YKMeta } from "/usr/src/nuxt3-app/pages/scout.vue?macro=true";
import { default as settingsHEhVIocTCuMeta } from "/usr/src/nuxt3-app/pages/settings.vue?macro=true";
import { default as _91id_931583b0flOMMeta } from "/usr/src/nuxt3-app/pages/teams/[id].vue?macro=true";
import { default as _91id_93jF0kJABXlWMeta } from "/usr/src/nuxt3-app/pages/teams/attachments/[id].vue?macro=true";
import { default as index5xpbIqJnRtMeta } from "/usr/src/nuxt3-app/pages/teams/attachments/index.vue?macro=true";
import { default as indexiZOyfyVr5MMeta } from "/usr/src/nuxt3-app/pages/teams/index.vue?macro=true";
import { default as usersK9lU4U3bY3Meta } from "/usr/src/nuxt3-app/pages/users.vue?macro=true";
export default [
  {
    name: "attachment-add",
    path: "/attachment/add",
    component: () => import("/usr/src/nuxt3-app/pages/attachment/add.vue")
  },
  {
    name: "attachments-id",
    path: "/attachments/:id()",
    component: () => import("/usr/src/nuxt3-app/pages/attachments/[id].vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/usr/src/nuxt3-app/pages/dashboard.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt3-app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/usr/src/nuxt3-app/pages/login.vue")
  },
  {
    name: "matches",
    path: "/matches",
    component: () => import("/usr/src/nuxt3-app/pages/matches.vue")
  },
  {
    name: "notes",
    path: "/notes",
    component: () => import("/usr/src/nuxt3-app/pages/notes.vue")
  },
  {
    name: "predict",
    path: "/predict",
    component: () => import("/usr/src/nuxt3-app/pages/predict.vue")
  },
  {
    name: "scout",
    path: "/scout",
    component: () => import("/usr/src/nuxt3-app/pages/scout.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/nuxt3-app/pages/settings.vue")
  },
  {
    name: "teams-id",
    path: "/teams/:id()",
    component: () => import("/usr/src/nuxt3-app/pages/teams/[id].vue")
  },
  {
    name: "teams-attachments-id",
    path: "/teams/attachments/:id()",
    component: () => import("/usr/src/nuxt3-app/pages/teams/attachments/[id].vue")
  },
  {
    name: "teams-attachments",
    path: "/teams/attachments",
    component: () => import("/usr/src/nuxt3-app/pages/teams/attachments/index.vue")
  },
  {
    name: "teams",
    path: "/teams",
    component: () => import("/usr/src/nuxt3-app/pages/teams/index.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/usr/src/nuxt3-app/pages/users.vue")
  }
]